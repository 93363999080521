<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>营地管理</el-breadcrumb-item>
            <el-breadcrumb-item>营地详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-card style="margin-top: 15px">
            <el-form
                label-width="130px"
                :model="campInfoForm"
                ref="campInfoFormRef"
                :rules="campInfoFormRules"
            >
                <el-row :gutter="5">
                    <el-col :span="8">
                        <el-form-item label="营地名称" prop="name">
                            <el-input
                                v-model="campInfoForm.name"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="营地类型" prop="type">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="campInfoForm.type"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_campType"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="地点" prop="place">
                            <el-input
                                v-model="campInfoForm.place"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="8">
                        <el-form-item label="年龄段" prop="ageRange">
                            <el-input
                                v-model="campInfoForm.ageRange"
                                placeholder="请输入(例:8-12)"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开闭营日期" prop="dateTimeRange">
                            <el-date-picker
                                v-model="campInfoForm.dateTimeRange"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                style="width: 100%"
                                value-format="timestamp"
                                @input="dateTimeRangeChange()"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="时长" prop="duration">
                            <el-input
                                v-model="campInfoForm.duration"
                                disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="8">
                        <el-form-item label="价格" prop="price">
                            <el-input
                                v-model="campInfoForm.price"
                                type="number"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人" prop="linkUserName">
                            <el-input
                                v-model="campInfoForm.linkUserName"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系电话" prop="linkUserPhone">
                            <el-input
                                v-model="campInfoForm.linkUserPhone"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="8">
                        <el-form-item label="具体地址" prop="address">
                            <el-input
                                v-model="campInfoForm.address"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="报名日期" prop="signUpDateTimeRange">
                            <el-date-picker
                                v-model="campInfoForm.signUpDateTimeRange"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                style="width: 100%"
                                value-format="timestamp"
                                @input="signUpDateTimeRangeChange()"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否关闭" prop="closeStatus">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="campInfoForm.closeStatus"
                            >
                                <el-option
                                    v-for="item in dictCampstatus"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-table
                    v-if="campDiscounts.length > 0"
                    :data="campDiscounts"
                    header-cell-class-name="tableHeaderStyle"
                    :row-class-name="tableRowClassName"
                    border
                    stripe
                >
                    <el-table-column label="#" type="index"></el-table-column>
                    <el-table-column
                        label="开始时间"
                        prop="beginTime"
                        :formatter="timestampFormatter"
                    ></el-table-column>
                    <el-table-column
                        label="结束时间"
                        prop="endTime"
                        :formatter="timestampFormatter"
                    ></el-table-column>
                    <el-table-column
                        label="折扣后价格"
                        prop="discountPrice"
                    ></el-table-column>
                    <el-table-column label="是否启用" prop="status">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.status"
                                :active-value="true"
                            ></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="removeTeamTips(scope.$index)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <el-row :gutter="5" style="margin-top: 20px">
                    <div style="text-align: center">
                        <el-button
                            type="primary"
                            icon="el-icon-circle-plus-outline"
                            @click="addDiscountInfo"
                            >添加折扣信息</el-button
                        >
                    </div>
                </el-row>
                <el-row :gutter="5">
                    <el-col>
                        <el-form-item label="封面图片" prop="coverUrl">
                            <span style="color: red"
                                >宽高要求 690px * 280px</span
                            >
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadQuestionImageResults.uploadUrl"
                                :show-file-list="false"
                                :on-success="uploadCoverImgSuccess"
                                :before-upload="beforeCoverImgUpload"
                                :headers="uploadQuestionImageResults.headerObj"
                                :data="{
                                    filePath: 'spbcnapp/camp/coverImg/',
                                }"
                            >
                                <img
                                    v-if="campInfoForm.coverUrl"
                                    class="img-cover"
                                    :src="campInfoForm.coverUrl"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col>
                        <el-form-item label="详情图片" prop="detailUrl">
                            <span style="color: red">宽度要求 750px</span>
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadQuestionImageResults.uploadUrl"
                                :show-file-list="false"
                                :on-success="uploadDetailImgSuccess"
                                :headers="uploadQuestionImageResults.headerObj"
                                :before-upload="beforeDetailImgUpload"
                                :data="{
                                    filePath: 'spbcnapp/camp/detailImg/',
                                }"
                            >
                                <img
                                    v-if="campInfoForm.detailUrl"
                                    class="img-detail"
                                    :src="campInfoForm.detailUrl"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="5">
                    <el-col>
                        <el-form-item label="合同图片" prop="contractUrl">
                            <span style="color: red">宽度要求 750px</span>
                            <el-upload
                                class="avatar-uploader"
                                :action="upLoadPicUrl"
                                :show-file-list="false"
                                :on-success="uploadContractImgSuccess"
                                :before-upload="beforeDetailImgUpload"
                                :data="{
                                    upLoadPicUrl: 'camp/contractImg/',
                                }"
                            >
                                <img
                                    v-if="campInfoForm.contractUrl"
                                    class="img-detail"
                                    :src="campInfoForm.contractUrl"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
            <el-row type="flex" justify="center" style="margin-top: 20px">
                <el-col :span="4">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="saveDetailInfo"
                        >保存</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-dialog
            title="添加折扣信息"
            :visible.sync="addDiscountInfoDialog"
            width="50%"
            @close="handleCloseDialog"
        >
            <el-form
                :model="addDiscountModel"
                label-width="100px"
                ref="addDiscountFormRef"
                :rules="addDiscountRules"
                style="width: 100%"
            >
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="开始时间" prop="beginTime">
                            <el-date-picker
                                type="datetime"
                                placeholder="选择日期/时间"
                                value-format="timestamp"
                                style="width: 100%"
                                v-model="addDiscountModel.beginTime"
                                @change="addBeginTimeChangeStart"
                                clearable
                                :picker-options="addTimepickerOptionsStart"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="结束时间" prop="endTime">
                            <el-date-picker
                                type="datetime"
                                placeholder="选择日期/时间"
                                value-format="timestamp"
                                style="width: 100%"
                                clearable
                                v-model="addDiscountModel.endTime"
                                @change="addEndTimeChangeEnd"
                                :picker-options="addTimepickerOptionsEnd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="折后价格" prop="discountPrice">
                            <el-input
                                v-model="addDiscountModel.discountPrice"
                                type="number"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleToAdd">添 加</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { campInfo, campInsert, campEdit } from '@/http/api'

export default {
  data () {
    return {
      uploadQuestionImageResults: {
        uploadUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      addDiscountModel: {
        beginTime: '',
        endTime: '',
        // discount: null,
        discountPrice: null,
        status: true
      },
      addTimepickerOptionsStart: {},
      addTimepickerOptionsEnd: {},
      addDiscountRules: {
        beginTime: [{ required: true, message: '请选择', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择', trigger: 'change' }],
        // discount: [{ required: true, message: '请输入', trigger: 'blur' }],
        // discountPrice: [{ required: true, message: '请输入', trigger: 'blur' }],
        status: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      campId: this.$route.query.campId,
      addDiscountInfoDialog: false,
      campInfoForm: {
        name: '',
        type: '',
        place: '',
        ageRange: '',
        dateTimeRange: [],
        signUpDateTimeRange: [],
        duration: '',
        price: '',
        coverUrl: '',
        detailUrl: '',
        contractUrl: '',
        closeStatus: null
      },
      campDiscounts: [],
      campInfoFormRules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        place: [{ required: true, message: '请输入', trigger: 'blur' }],
        ageRange: [{ required: true, message: '请输入', trigger: 'blur' }],
        dateTimeRange: [{ required: true, message: '请选择', trigger: 'blur' }],
        signUpDateTimeRange: [{ required: true, message: '请选择', trigger: 'blur' }],
        price: [{ required: true, message: '请输入', trigger: 'blur' }],
        linkUserName: [{ required: true, message: '请输入', trigger: 'blur' }],
        linkUserPhone: [{ required: true, message: '请输入', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
        detailUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
        closeStatus: [{ required: true, message: '请选择', trigger: 'blur' }]
        // contractUrl: [{ required: true, message: '请上传', trigger: 'blur' }]
      },
      dict_campType: this.$userInfo.getDataList(this, 't_camp_type').then(value => { this.dict_campType = value }),
      upLoadPicUrl: this.$http.defaults.baseURL + 'system/upLoadFile',
      dictCampstatus: this.$userInfo.dictCampstatus()
    }
  },
  created () {
    this.getCampDetail()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    tableRowClassName (row, rowIndex) {
      row.index = rowIndex
    },
    async getCampDetail () {
      if (this.campId === '') return // 新增时不请求信息
      campInfo(this.campId).then((res) => {
        res.data.type = res.data.type + ''
        this.campInfoForm = res.data
        this.campDiscounts = res.data.campDiscounts.map(item => {
          item.beginTime = this.$xcUtils.date2Timestamp(item.beginTime)
          item.endTime = this.$xcUtils.date2Timestamp(item.endTime)
          return item
        })
        this.campInfoForm.dateTimeRange = [this.$xcUtils.date2Timestamp(this.campInfoForm.startTime), this.$xcUtils.date2Timestamp(this.campInfoForm.endTime)]
        this.campInfoForm.signUpDateTimeRange = [this.$xcUtils.date2Timestamp(this.campInfoForm.beginDate), this.$xcUtils.date2Timestamp(this.campInfoForm.endDate)]
        console.log('campInfoForm', this.campInfoForm)
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 添加折扣信息点击事件
    addDiscountInfo () {
      this.addDiscountInfoDialog = true
    },
    handleCloseDialog () {
      this.$refs.addDiscountFormRef.resetFields()
    },
    // 修改开始时间的判断
    addBeginTimeChangeStart () {
      if (this.addDiscountModel.endTime) {
        if (this.addDiscountModel.endTime <= this.addDiscountModel.beginTime) {
          this.$message.warning('开始时间必须小于结束时间！')
          this.addDiscountModel.beginTime = ''
        }
      }
      this.addTimepickerOptionsEnd = Object.assign({}, this.addTimepickerOptionsEnd, {
        disabledDate: (time) => {
          if (this.addDiscountModel.beginTime) {
            return new Date(time).getTime() < new Date(this.addDiscountModel.beginTime).getTime()
          }
        }
      })
    },
    // 修改结束时间的判断
    addEndTimeChangeEnd () {
      if (this.addDiscountModel.beginTime) {
        if (this.addDiscountModel.endTime <= this.addDiscountModel.beginTime) {
          this.$message.warning('结束必须大于开始时间！')
          this.addDiscountModel.endTime = ''
        }
      }
      this.addTimepickerOptionsStart = Object.assign({}, this.addTimepickerOptionsStart, {
        disabledDate: (time) => {
          if (this.addDiscountModel.endTime) {
            return new Date(time).getTime() > new Date(this.addDiscountModel.endTime).getTime()
          }
        }
      })
    },
    // 添加折扣点击事件
    handleToAdd () {
      this.campDiscounts.push(JSON.parse(JSON.stringify(this.addDiscountModel)))
      console.log('campDiscounts', this.campDiscounts)
      this.addDiscountInfoDialog = false
    },
    // 删除折扣点击事件
    removeTeamTips (val) {
      console.log('rowData', val)
      this.campDiscounts.splice(val, 1)
    },
    beforeCoverImgUpload (file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      var isSize = this.uploadCoverImgWidth(file)
      return isLt5M && isSize
    },
    uploadCoverImgWidth (file) {
      var _this = this
      let imgWidth = ''
      let imgHight = ''
      const isSize = new Promise(function (resolve, reject) {
        var width = 690
        var height = 280
        var _URL = window.URL || window.webkitURL
        var img = new Image()
        img.onload = function () {
          imgWidth = img.width
          imgHight = img.height
          var valid = img.width === width && img.height === height
          valid ? resolve() : reject(new Error('img size error'))
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning({ message: '上传文件的图片大小不合符标准,宽需要为690px,高需要为280px。当前上传图片的宽高分别为:' + imgWidth + 'px和' + imgHight + 'px', btn: false })
        return Promise.reject(new Error('img size error'))
      })
      console.log(isSize)
      return isSize
    },
    beforeDetailImgUpload (file) {
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      var isSize = this.uploadDetailImgWidth(file)
      return isLt5M && isSize
    },
    uploadDetailImgWidth (file) {
      var _this = this
      let imgWidth = ''
      const isSize = new Promise(function (resolve, reject) {
        var width = 750
        var _URL = window.URL || window.webkitURL
        var img = new Image()
        img.onload = function () {
          imgWidth = img.width
          var valid = img.width === width
          valid ? resolve() : reject(new Error('img size error'))
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning({ message: '上传文件的图片大小不合符标准,宽需要为750px。当前上传图片的宽为:' + imgWidth + 'px', btn: false })
        return Promise.reject(new Error('img size error'))
      })
      console.log(isSize)
      return isSize
    },
    uploadCoverImgSuccess (res) {
      console.log('res', res)
      this.$set(this.campInfoForm, 'coverUrl', res.data)
    },
    uploadDetailImgSuccess (res) {
      this.$set(this.campInfoForm, 'detailUrl', res.data)
    },
    uploadContractImgSuccess (res) {
      this.$set(this.campInfoForm, 'contractUrl', res.data)
    },
    saveDetailInfo () {
      this.$refs.campInfoFormRef.validate(async valid => {
        if (!valid) return
        if (!this.$xcUtils.checkPhone(this.campInfoForm.linkUserPhone)) {
          return this.$message.warning('联系电话格式错误')
        }
        if (this.campDiscounts.length !== 0) {
          this.campInfoForm.campDiscounts = this.campDiscounts
        } else {
          delete this.campInfoForm.campDiscounts
        }
        console.log('campInfoForm', this.campInfoForm)
        if (this.campInfoForm.id) {
          campEdit(this.campInfoForm).then((res) => {
            this.$message.success('保存成功')
            this.$router.back()
          }).catch((err) => {
            console.log('err', err)
          })
        } else {
          campInsert(this.campInfoForm).then((res) => {
            this.$message.success('新增成功')
            this.$router.back()
          }).catch((err) => {
            console.log('err', err)
          })
        }
      })
    },
    dateTimeRangeChange () {
      this.campInfoForm.startTime = this.campInfoForm.dateTimeRange[0]
      this.campInfoForm.endTime = this.campInfoForm.dateTimeRange[1]
      var temp = (this.campInfoForm.dateTimeRange[1] - this.campInfoForm.dateTimeRange[0]) / 1000 / 60 / 60 / 24
      this.$set(this.campInfoForm, 'duration', (temp + 1) + '天' + temp + '晚')
    },
    signUpDateTimeRangeChange () {
      this.campInfoForm.beginDate = this.campInfoForm.signUpDateTimeRange[0]
      this.campInfoForm.endDate = this.campInfoForm.signUpDateTimeRange[1]
    },
    timestampFormatter (row, col, cell) {
      return this.$xcUtils.timestamp2Date(parseInt(cell))
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 180px;
    line-height: 180px;
    text-align: center;
}
.img-cover {
    width: 750px;
    display: block;
}

.img-detail {
    width: 750px;
    display: block;
}
</style>
